import { createApi } from "@reduxjs/toolkit/query/react";
import type {
  BalancesParams,
  BalancesReturn,
  GasParams,
  PointsParams,
  PointsReturn,
  PriceParams,
  PriceReturn,
  QuoteParams,
  QuoteReturn,
  SwapParams,
  SwapReturn,
} from "./types";
import { SAFE_WALLET_ADDRESS } from "@/app/services/constants";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getAddress, zeroAddress } from "viem";
import { ApprovalReturn } from "./types";

class AxiosQueue {
  private axiosInstance: AxiosInstance;
  private requestQueue: (() => Promise<void>)[] = [];
  private processing: boolean = false;

  constructor() {
    this.axiosInstance = axios.create();
  }

  private async processQueue(): Promise<void> {
    if (this.processing || this.requestQueue.length === 0) {
      return;
    }

    this.processing = true;
    const request = this.requestQueue.shift();
    if (request) {
      await request();
    }
    this.processing = false;

    setTimeout(() => this.processQueue(), 1000); // Wait for 1 second before processing the next request
  }

  public async enqueueRequest(
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      this.requestQueue.push(async () => {
        try {
          const response = await this.axiosInstance.request(config);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });

      if (!this.processing) {
        this.processQueue();
      }
    });
  }
}

const axiosInstance = new AxiosQueue();

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params, headers }: any) => {
    try {
      const result = await axiosInstance.enqueueRequest({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError: any) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const oneInchApi = createApi({
  reducerPath: "oneInchApi",
  baseQuery: axiosBaseQuery({ baseUrl: "/api/swap/" }),
  endpoints: (builder) => ({
    getBalances: builder.query<BalancesReturn, BalancesParams>({
      query: ({ tokens, address, chainId }) => ({
        url: `balance`,
        method: "POST",
        data: {
          tokens,
          address,
          chainId,
        },
      }),
    }),
    getPrice: builder.query<PriceReturn, PriceParams>({
      query: ({ addresses, chainId }) => ({
        url: `balance`,
        method: "POST",
        data: {
          tokens: addresses,
          address: zeroAddress,
          chainId,
        },
      }),
    }),
    getApproval: builder.query<ApprovalReturn, GasParams>({
      query: ({ chainId, approveAmount, tokenContractAddress }) => ({
        url: `approval`,
        method: "GET",
        params: {
          chainId,
          approveAmount,
          tokenContractAddress,
        },
      }),
    }),
    getQuote: builder.query<QuoteReturn, QuoteParams>({
      query: ({ chainId, src, dst, amount }) => ({
        url: `quote`,
        method: "GET",
        params: {
          src,
          dst,
          amount,
          chainId,
        },
      }),
    }),
    getSwap: builder.query<SwapReturn, SwapParams>({
      query: ({ chainId, src, dst, amount, address, slippage }) => {
        return {
          url: ``,
          method: "GET",
          params: {
            chainId,
            src,
            dst,
            amount,
            from: address,
            slippage,
          },
        };
      },
    }),
    getPoints: builder.query<PointsReturn, PointsParams>({
      query: ({ chainId, address }) => ({
        url: `points`,
        method: "GET",
        params: {
          address: address,
          chainId,
        },
      }),
    }),
  }),
});

export const {
  useGetApprovalQuery,
  useGetBalancesQuery,
  useGetPriceQuery,
  useGetQuoteQuery,
  useGetSwapQuery,
  useGetPointsQuery,
} = oneInchApi;
