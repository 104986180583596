import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SerializedTokenType } from "@/app/features/swap/slice";
import {
  DEFAULT_FAVOURITE_TOKENS,
  ONEINCH_NATIVE_ADDRESS,
} from "@/app/features/swap/constants";
import { RootState } from "@/app/store";
import { createSelector } from "reselect";
import { useAppSelector } from "@/app/hooks";

interface PersistedState {
  customTokens?: SerializedTokenType[];
  favouriteTokens?: SerializedTokenType[];
  favouritesSet?: boolean;
}

const initialPersistedState: PersistedState = {};

const persistedSlice = createSlice({
  name: "persistedSwap",
  initialState: initialPersistedState,
  reducers: {
    addCustomToken(state, action: PayloadAction<SerializedTokenType>) {
      if (!state.customTokens) {
        state.customTokens = [action.payload];
        return;
      }

      const tokenExists = state.customTokens.some(
        (token) => token.address === action.payload.address,
      );

      if (tokenExists) {
        state.customTokens = state.customTokens.filter(
          (token) =>
            token.address.toLowerCase() !==
            action.payload.address.toLowerCase(),
        );
      } else {
        state.customTokens.push(action.payload);
      }
    },
    toggleFavouriteToken(state, action: PayloadAction<SerializedTokenType>) {
      if (!state.favouriteTokens) {
        state.favouriteTokens = [action.payload];
        state.favouritesSet = true;
        return;
      }

      state.favouritesSet = true;

      if (action.payload.address === ONEINCH_NATIVE_ADDRESS) {
        const nativeTokenExists = state.favouriteTokens.some(
          (token) => token.address === ONEINCH_NATIVE_ADDRESS,
        );

        if (nativeTokenExists) {
          state.favouriteTokens = state.favouriteTokens.filter(
            (token) => token.address !== ONEINCH_NATIVE_ADDRESS,
          );
        } else {
          state.favouriteTokens.push(action.payload);
        }
      } else {
        const sameAddressTokenExists = state.favouriteTokens.some(
          (token) =>
            token.address !== ONEINCH_NATIVE_ADDRESS &&
            action.payload.address !== ONEINCH_NATIVE_ADDRESS &&
            token.address === action.payload.address,
        );

        if (sameAddressTokenExists) {
          state.favouriteTokens = state.favouriteTokens.filter(
            (token) =>
              token.address === ONEINCH_NATIVE_ADDRESS ||
              action.payload.address === ONEINCH_NATIVE_ADDRESS ||
              token.address !== action.payload.address,
          );
        } else {
          state.favouriteTokens.push(action.payload);
        }
      }
    },
  },
});

export const { toggleFavouriteToken, addCustomToken } = persistedSlice.actions;

export const selectCustomTokensFunc = (state: RootState) =>
  state.swapPersisted.customTokens || [];
export const selectCustomTokens = (chainId_: number) =>
  createSelector(
    [selectCustomTokensFunc, () => chainId_],
    (customTokens, chainId) => {
      return customTokens.filter((token) => token.chainId === chainId);
    },
  );

export function useCustomTokens(chainId: number) {
  return useAppSelector(selectCustomTokens(chainId));
}

export const selectFavoriteTokensFunc = (state: RootState) =>
  state.swapPersisted.favouriteTokens || [];
export const selectFavoritesSet = (state: RootState) =>
  state.swapPersisted.favouritesSet;

export const selectFavoriteTokens = (chainId_: number) =>
  createSelector(
    [selectFavoriteTokensFunc, selectFavoritesSet, () => chainId_],
    (favouriteTokens, favouritesSet, chainId) => {
      if (!favouritesSet) return DEFAULT_FAVOURITE_TOKENS[chainId];
      return favouriteTokens.filter((token) => token.chainId === chainId);
    },
  );

export function useFavoriteTokens(chainId: number) {
  return useAppSelector(selectFavoriteTokens(chainId));
}
export default persistedSlice.reducer;
