import { SerializedTokenType } from "@/app/features/swap/slice";
import { Address, getAddress } from "viem";

export const ONEINCH_NATIVE_ADDRESS: Address = getAddress(
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
);
export const NATIVES: Record<number, SerializedTokenType> = {
  1: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    logoURI: "./eth.svg",
    chainId: 1,
  },
  11_155_111: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    logoURI: "./eth.svg",
    chainId: 11_155_111,
  },
  8453: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    logoURI: "./base.svg",
    chainId: 8453,
  },
  56: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "BNB",
    name: "Binance Coin",
    logoURI: "./bnb.svg",
    chainId: 56,
  },
  42161: {
    symbol: "ETH",
    name: "Ether",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    decimals: 18,
    logoURI:
      "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png",
    chainId: 42161,
  },
  137: {
    symbol: "MATIC",
    name: "MATIC",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    decimals: 18,
    logoURI:
      "https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
    chainId: 137,
  },
};

export const WRAPPED_NATIVES: Record<number, SerializedTokenType> = {
  1: {
    address: getAddress("0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"),
    decimals: 18,
    symbol: "WETH",
    name: "WETH",
    logoURI: "./eth.svg",
    chainId: 1,
  },
  11_155_111: {
    address: getAddress("0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"),
    decimals: 18,
    symbol: "WETH",
    name: "WETH",
    logoURI: "./eth.svg",
    chainId: 11_155_111,
  },
  8453: {
    address: "0x4200000000000000000000000000000000000006",
    decimals: 18,
    symbol: "WETH",
    name: "WETH",
    logoURI: "./eth.svg",
    chainId: 8453,
  },
  56: {
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    decimals: 18,
    symbol: "WBNB",
    name: "Wrapped Binance Coin",
    logoURI: "./bnb.svg",
    chainId: 56,
  },
  42161: {
    symbol: "WETH",
    name: "WETH",
    address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    decimals: 18,
    logoURI:
      "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png",
    chainId: 42161,
  },
  137: {
    symbol: "WMATIC",
    name: "WMATIC",
    address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    decimals: 18,
    logoURI:
      "https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
    chainId: 137,
  },
};

export const ONEINCH_ROUTER_ADDRESS: Record<number, Address> = {
  1: "0x40aA958dd87FC8305b97f2BA922CDdCa374bcD7f",
  56: "0x2c34A2Fb1d0b4f55de51E1d0bDEfaDDce6b7cDD6",
  8453: "0x57df6092665eb6058DE53939612413ff4B09114E",
  42161: "0x70cBb871E8f30Fc8Ce23609E9E0Ea87B6b222F58",
  137: "0x3B86917369B83a6892f553609F3c2F439C184e31",
};

export const DEFAULT_TOKEN: Record<number, SerializedTokenType> = {
  1: {
    address: "0xcAb254f1a32343f11aB41fbdE90eCB410cDe348a",
    decimals: 18,
    symbol: "FROGE",
    name: "Froge",
    logoURI: "./froge.png",
    chainId: 1,
  },
};

export const DEFAULT_FAVOURITE_TOKENS: Record<number, SerializedTokenType[]> = {
  [1]: [NATIVES[1], DEFAULT_TOKEN[1]],
  [56]: [NATIVES[56]],
  [8453]: [NATIVES[8453]],
  [42161]: [NATIVES[42161]],
  [137]: [NATIVES[137]],
};

export const OMNICAT_TOKENS: Record<number, SerializedTokenType> = {
  1: {
    chainId: 1,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  56: {
    chainId: 56,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  8453: {
    chainId: 8453,
    address: "0xc48e605c7b722a57277e087a6170b9e227e5ac0a",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  137: {
    chainId: 137,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  42161: {
    chainId: 42161,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
};

export const FROGE_TOKEN = DEFAULT_TOKEN[1];
